import { useState, useEffect } from 'react';
import { useLocalStorage } from './use-hooks';
import { balance as fetchBalance } from '../api/account';
import {
  getUserProfile,
  getUserWinProgress,
  persistUserProfile,
} from '../api/profile';
import { useContext } from 'react';
import { createContext } from 'react';
import { useMemo } from 'react';
import { loadPersistentState, persistState } from '../utils/persist';
import { useInterval } from './use-interval';
import { useLoggerContext } from './use-logger';

const User = createContext();
export function UserContext({ children }) {
  const userProps = useUser();
  const persistedUserProps = useMemo(() => userProps, [userProps]);
  return <User.Provider value={persistedUserProps}>{children}</User.Provider>;
}
export const useUserContext = () => useContext(User);

function useUser() {
  const logger = useLoggerContext();
  const [isLogged, setIsLogged] = useState(false);
  const [onLogout, setOnLogout] = useState(null);
  const [email, setEmail] = useLocalStorage('email', '');
  const [session, setSession] = useState(loadPersistentState('session'));
  const [lang, setLang] = useLocalStorage('lng', null);
  const [_nickname, setNickname] = useLocalStorage('nickname', '');
  const [league, setLeague] = useLocalStorage('league', 0);
  const [isLoading, setIsLoading] = useState(false);
  const initBalance = 100;
  const [balance, setBalance] = useLocalStorage('balance', initBalance);
  const [winBalance, setWinBalance] = useLocalStorage('winBalance', 0);

  async function fetchUserData(email) {
    setIsLoading(true);
    try {
      const resps = await Promise.all([
        fetchBalance(),
        getUserWinProgress(),
        getUserProfile(),
      ]);

      const [respBalance, respWinProgress, respProfile] = resps;

      const errors = [
        respBalance.error,
        respWinProgress.error,
        respProfile.error,
      ];
      //Logout
      if (errors.includes('jwt token expired')) {
        setIsLoading(false);
        resetUser('expired');
        return {};
      }

      if (errors.includes('jwt token not valid')) {
        setIsLoading(false);
        resetUser('invalid');
        return {};
      }

      //Balance
      const newBalance = Number(
        respBalance.balances?.find((b) => b.code === 'main')?.amount // or === 'bonus'
      );
      setBalance(newBalance);

      //Win progress
      const winProgress = Number(respWinProgress.winProgressDaily);
      setWinBalance(winProgress ? winProgress : 0); //setWinBalancePersisted(newWinBalance ? newWinBalance * 1 : 0);

      //Profile
      const profile = respProfile.profile;
      if (profile) {
        const newNickName = profile?.nickname
          ? profile.nickname
          : email?.length > 0
          ? email?.split('@').shift()
          : email;
        setNickname(newNickName);
        setLeague(profile.league);
        if (profile?.lang) setLang(profile.lang);
        else persistUserProfile({ lang });

        persistState('enable_push_news', profile?.enable_push_news);
        persistState('enable_push_vip', profile?.enable_push_vip);
        persistState('enable_push_bonus', profile?.enable_push_bonus);
        setIsLoading(false);
        return {
          nickName: newNickName,
          league: profile.league,
          email,
        };
      }
      setIsLoading(false);
      return {
        email,
      };
    } catch (e) {
      setIsLoading(false);
      resetUser(`error: ${e.message}`);
      return {};
    }
  }

  //Init
  useEffect(() => {
    const session = loadPersistentState('session');
    if (session) initUser(email);
    else resetUser('init');
  }, []);

  //Listen logout
  useInterval(() => {
    const newSession = loadPersistentState('session');
    if (!newSession?.token && session?.token) resetUser('listen');
  }, 1000);

  //Call it after login
  async function initUser(email) {
    const newSession = loadPersistentState('session');
    setSession(newSession);
    setEmail(email);
    const result = await fetchUserData(email);
    setIsLogged(true);
    return result;
  }

  //Setter for logout callback
  const setOnUserLogout = (callback) => {
    setOnLogout(() => () => !!callback && callback());
  };

  //Call it after logout
  function resetUser(msg = 'default') {
    if (email) logger?.event('logout', { email, msg });
    setBalance(initBalance);
    setWinBalance(0);
    setEmail('');
    setNickname(null);
    setLang(null);
    setSession(null);
    persistState('session', null);
    setIsLogged(false);
    setIsLoading(false);
    !!onLogout && onLogout();
  }

  return {
    initUser,
    resetUser,
    user: { email, league },
    balance,
    winBalance,
    setBalance,
    setWinBalance,
    setOnUserLogout,
    isLoading,
    isLogged,
  };
}
