import useWebSocket from 'react-use-websocket';
import { useRef } from 'react';
import { useEffect } from 'react';

const WS_URL = 'wss://ws.boxo.trade/ws';

export function useWssPrices(callback) {
  const callbackRef = useRef();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const { lastJsonMessage, readyState } = useWebSocket(WS_URL, {
    share: false,
    shouldReconnect: () => true,
  });

  useEffect(() => {
    console.log('wss connection state changed: ', readyState);
  }, [readyState]);

  useEffect(() => {
    if (lastJsonMessage) {
      callbackRef.current(lastJsonMessage);
    }
  }, [lastJsonMessage]);
}
