import { t } from 'i18next';
import { useState } from 'react';
import { createContext } from 'react';
import { useContext } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import {
  getBonuses,
  getChallengeBonusProgress,
  persistBonusSeen,
  claimBonusPersisted,
} from '../api/bonus';
import { useLocalStorage, useSessionStorage } from './use-hooks';
import { useUserContext } from './use-user';

const Bonuses = createContext();
export function BonusesContext({ children }) {
  const bonusesProps = useBonuses();
  const persistedBonusesProps = useMemo(() => bonusesProps, [bonusesProps]);
  return (
    <Bonuses.Provider value={persistedBonusesProps}>
      {children}
    </Bonuses.Provider>
  );
}
export const useBonusesContext = () => useContext(Bonuses);

export function useBonuses() {
  //Old format: {name: "Bonus", descr: "Amount $5", amount: "500", expiration: 1703361351044, claimed: true}
  const [bonuses, setBonuses] = useLocalStorage('bonuses', []);
  const [bonusTime, setBonusTime] = useSessionStorage('new-user-bonus-time', 0);

  const [isLoading, setIsLoading] = useState(false);
  const { isLogged } = useUserContext();

  function fetchData(onDone) {
    setIsLoading(true);

    function parse(params) {
      try {
        return params ? JSON.parse(params) : null;
      } catch {
        return { amount: 0 };
      }
    }

    getBonuses()
      .then(async (response) => {
        getChallengeBonusProgress()
          .then((resp) => {
            const progress = resp.progress;
            const data = response?.bonuses;
            const newBonuses = data?.map((b) => {
              return {
                ...b,
                typeId: b.type_id,
                created: b.created_at ? new Date(b.created_at).getTime() : null,
                availableAt: b.available_at
                  ? new Date(b.available_at).getTime()
                  : null,
                expired: b.expired_at
                  ? new Date(b.expired_at).getTime()
                  : bonusTime,
                lastSeenAt: b.last_seen_at
                  ? new Date(b.last_seen_at).getTime()
                  : null,
                type: b.type,
                params: parse(b.params),
                descr: '',
                progress: b.type_id === 'ChallengeBonus' ? progress : null,
              };
            });
            setBonuses(newBonuses);
            setIsLoading(false);
            onDone && onDone(newBonuses);
          })
          .catch((e) => {
            setBonuses([]);
            setIsLoading(false);
            console.error(e);
          });
      })
      .catch((e) => {
        setBonuses([]);
        setIsLoading(false);
        console.error(e);
      });
  }

  useEffect(() => {
    if (!bonusTime) setBonusTime(Date.now() + 24 * 60 * 60 * 1000);
    if (!isLogged) setBonuses([]);
    else fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  function claimBonus(bonus, onSuccess, onFailed) {
    if (!isLogged) {
      onFailed('Can not claim bonus. Please login.');
      return;
    }
    claimBonusPersisted(bonus)
      .then((data) => {
        if (data?.success) {
          const amount = data.amount * 1;
          const newBonuses = bonuses.filter((b) => !(b.id === bonus.id));
          setBonuses(newBonuses);
          onSuccess(amount);
        } else {
          if (data?.error === 'bonus is not found')
            onFailed(t('Bonus has been already spent'));
          else if (data?.error) onFailed(`Bonus error: ${data?.error}`);
          else onFailed(`Unknown bonus error: ${bonus?.id}`);
        }
      })
      .catch((e) => {
        onFailed(e);
      });
  }

  function setBonusSeen(bonus) {
    if (!isLogged || !bonus) return;
    persistBonusSeen(bonus);
  }

  function getActualBonuses() {
    /*     const welcomeBonus = getActualWelcomeBonus();
    if (welcomeBonus) {
      return bonuses.filter(
        (b) =>
          b.typeId === welcomeBonus.typeId &&
          (!b.expired || b.expired > Date.now())
      );
    }
 */
    return bonuses.filter((b) => !b.expired || b.expired > Date.now());
  }

  function getActualChallengeBonus() {
    return bonuses.find(
      (b) => b.typeId === 'ChallengeBonus' && b.expired > Date.now()
    );
  }

  function getActualWelcomeBonus() {
    return bonuses.find(
      (b) => b.typeId === 'WelcomeBonusFreeBoxes' && b.expired > Date.now()
    );
  }

  function getActualRecoveryBonus() {
    return bonuses.find((b) => b.typeId === 'RecoveryBonus');
  }

  function getActualPushNotificationsBonus() {
    return bonuses.find(
      (b) => b.typeId === 'PushNotificationsBonus' && b.expired > Date.now()
    );
  }

  function getActualAdsBonus() {
    return bonuses.find((b) => b.typeId === 'AdBonus');
  }

  return {
    getActualBonuses,
    claimBonus,
    setBonusSeen,
    bonuses,
    setBonuses,
    isLoading,
    fetchData,
    getActualChallengeBonus,
    getActualWelcomeBonus,
    getActualPushNotificationsBonus,
    getActualRecoveryBonus,
    getActualAdsBonus,
  };
}
