import { useCallback } from 'react';
import { useEffect, useRef } from 'react';

export function useInterval(callback, delay, useImmediately = false) {
  const savedCallback = useRef(callback);
  const intervalIdRef = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };

    if (delay !== null) {
      intervalIdRef.current = setInterval(tick, delay);
    }

    const id = intervalIdRef.current;
    return () => {
      clearInterval(id);
    };
  }, [delay]);

  useEffect(() => {
    const id = intervalIdRef.current;
    return () => {
      clearInterval(id);
    };
  }, []);

  const resetInterval = useCallback(() => {
    clearInterval(intervalIdRef.current);
    //intervalIdRef.current = setInterval(savedCallback.current, restartDelay);
  }, []);

  return resetInterval;
}

export function usePoll([{ method, params, ...rest }, callRpc], delay) {
  useInterval(() => callRpc(method, ...params), delay);
  return [{ method, params, ...rest }, callRpc];
}

/* 
import { useEffect, useRef } from 'react';

export function useInterval(callback, delay, useImmediately = false) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    let timeoutId;

    function tick() {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        Promise.resolve(savedCallback.current()).then(tick());
      }, delay);
    }

    if (delay !== null) {
      if (useImmediately) Promise.resolve(savedCallback.current()).then(tick());
      else tick();
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [delay, useImmediately]);
}

export function usePoll([{ method, params, ...rest }, callRpc], delay) {
  useInterval(() => callRpc(method, ...params), delay);
  return [{ method, params, ...rest }, callRpc];
}

*/
