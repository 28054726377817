import {
  handleMiddlewareError,
  maybe_refresh_auth_token_middlware,
} from './utils';

export async function claimBonusPersisted(bonus) {
  const { data } = await maybe_refresh_auth_token_middlware((api) =>
    api.post('/api/bonus/claim', {
      id: bonus.id,
    })
  ).catch(handleMiddlewareError);
  return data;
}

export async function persistBonusSeen(bonus) {
  const { data } = await maybe_refresh_auth_token_middlware((api) =>
    api.post('/api/bonus/seen', {
      id: bonus.id,
    })
  ).catch(handleMiddlewareError);
  return data;
}

export async function getBonuses() {
  const { data } = await maybe_refresh_auth_token_middlware((api) =>
    api.get('/api/getBonuses', {})
  ).catch(handleMiddlewareError);
  return data;
}

export async function getChallengeBonusProgress() {
  const { data } = await maybe_refresh_auth_token_middlware((api) =>
    api.get('/api/bonus/getChallengeProgress', {})
  ).catch(handleMiddlewareError);
  return data;
}
