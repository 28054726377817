import { useEffect, useState } from 'react';
import { t } from 'i18next';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { ModalLayout } from '../shared/layout';
import { useBonuses, useBonusesContext } from '../shared/hooks/use-bonus';
import {
  isAndroid,
  isIos,
  isPwa,
  millisecondsLeftToStr,
  tryToParseJSON,
} from '../shared/utils';
import { SelectionList, TodoList } from '../components/terminal';
import { InstallDialog } from './install';
import Loader from './loader';
import { SystemDialog } from '../shared/dialogs';
import { useLocalStorage, useSessionStorage } from '../shared/hooks/use-hooks';
import { useInterval } from '../shared/hooks/use-interval';
import { useSoundContext } from '../shared/hooks/use-sound';
import {
  loadPersistentState,
  persistSessionState,
  persistState,
} from '../shared/utils/persist';
import { useUserContext } from '../shared/hooks/use-user';
import { AdsenseContainer } from '../shared/hooks/use-adsense';
import { useLoggerContext } from '../shared/hooks/use-logger';
import { MonetagContainer } from '../shared/hooks/use-monetag';

function BonusListDialog({
  logger,
  email,
  onClaimBonus,
  onClaimBonusError,
  onLogin,
}) {
  const { isLogged } = useUserContext();

  const [bonus, setBonus] = useState(null);
  const { getActualBonuses, claimBonus, isLoading } = useBonuses();

  const [bonuses, setBonuses] = useState([]);
  useState(null);
  const location = useLocation();

  useEffect(() => {
    if (!email) {
      setBonuses([]);
    } else {
      if (isLoading) return;
      setBonuses(getActualBonuses());
    }
  }, [isLoading]);

  const [isClaiming, setIsClaiming] = useState(false);

  function doClaimBonus(claimedBonus) {
    /*  
    //DEBUG -- Prevent claiming
    const newBonus = { ...claimedBonus, claimedAmount: 0 };
    setBonus(newBonus);
    setIsClaiming(false);
    navigate('./success', { replace: true });
    return;
    */
    if (isClaiming) return;
    setIsClaiming(true);

    claimBonus(
      claimedBonus,
      (amount) => {
        const newBonus = { ...claimedBonus, claimedAmount: amount };
        setBonus(newBonus);
        setIsClaiming(false);
        navigate('./success', { replace: true });
      },
      (error, e) => {
        setIsClaiming(false);
        onClaimBonusError &&
          onClaimBonusError(claimedBonus, `${error}${e ? ' ' + e : ''}`);
      }
    );
  }

  const navigate = useNavigate();
  function doPlay() {
    persistSessionState(location.pathname, null); //Prevent <Back
    navigate('/app', { replace: true });
  }

  return (
    <>
      <Routes>
        {isLogged && (
          <>
            <Route
              path="/"
              element={
                isClaiming || isLoading ? (
                  <Loader overlay />
                ) : (
                  <BonusList
                    bonuses={bonuses}
                    logger={logger}
                    email={email}
                    onSetBonus={setBonus}
                  />
                )
              }
            />

            <Route
              path="/challenge"
              element={
                false && isLoading ? (
                  <Loader overlay />
                ) : (
                  <ChallengeBonusDialog
                    onClaimBonus={doClaimBonus}
                    onPlay={doPlay}
                  />
                )
              }
            />

            <Route
              path="/welcome"
              element={
                <WelcomeBonusDialog
                  logger={logger}
                  onClaimBonus={doClaimBonus}
                  hidePushNotificationsTask={true}
                  isLoading={isLoading}
                />
              }
            />
            <Route
              path="/push"
              element={
                <WelcomeBonusDialog
                  logger={logger}
                  onClaimBonus={doClaimBonus}
                  hidePushNotificationsTask={false}
                  isLoading={isLoading}
                />
              }
            />
            <Route
              path="/recovery"
              element={
                <RecoveryBonusDialog
                  onClaimBonus={doClaimBonus}
                  onPlay={doPlay}
                />
              }
            />

            <Route
              path="/success"
              element={
                isClaiming ? (
                  <Loader overlay />
                ) : (
                  <SuccessBonusDialog
                    bonus={bonus}
                    onClaimedBonus={() => {
                      onClaimBonus(bonus);
                    }}
                  />
                )
              }
            />
            <Route path="/*" element={<NoBonusDialog />} />
          </>
        )}

        <Route
          path="/welcome/token/:token"
          element={
            <WelcomeBonusDialog
              logger={logger}
              onClaimBonus={doClaimBonus}
              hidePushNotificationsTask={true}
              onLogin={onLogin}
              isLoading={isLoading}
            />
          }
        />

        <Route
          path="/health"
          element={<HealthBonusDialog onClaimBonus={doClaimBonus} />}
        />

        <Route
          path="/no-auth"
          element={<WelcomeBonusDialog logger={logger} />}
        />

        <Route path="/*" element={<WelcomeBonusDialog logger={logger} />} />
      </Routes>
    </>
  );
}

function BonusList({ bonuses, onSetBonus }) {
  const navigate = useNavigate();

  const onClose = () => {
    navigate('/app', { replace: true });
  };

  const location = useLocation();

  function getBonusAttr(bonus) {
    const bonusType = bonus?.typeId;
    if (bonusType === '2ndWelcomeBonusFreeBoxes')
      return {
        name: t('Welcome bonus'),
        imgUrl: '/img/bonus-box.svg',
      };
    if (bonusType === 'WelcomeBonusFreeBoxes')
      return {
        name: t('Welcome bonus'),
        imgUrl: '/img/bonus-box.svg',
      };
    if (bonusType === 'PushNotificationsBonus')
      return {
        name: t('Notifications bonus'),
        imgUrl: '/img/plane.svg',
      };
    if (bonusType === 'ChallengeBonus')
      return {
        name: t('Achievement bonus'),
        imgUrl: '/img/progress.svg',
      };
    if (bonusType === 'RecoveryBonus') {
      const enabled =
        bonus?.availableAt === null || bonus?.availableAt - Date.now() < 0;
      return {
        name: t('Recovery bonus'),
        descr: enabled ? null : t('Recently claimed'),
        imgUrl: '/img/heart.svg',
        disabled: !enabled,
      };
    }
    if (bonusType === 'AdBonus') {
      const enabled =
        bonus?.availableAt === null || bonus?.availableAt - Date.now() < 0;
      return {
        name: t('Health bonus'),
        descr: enabled ? null : t('Recently claimed'),
        imgUrl: '/img/health.svg',
        disabled: !enabled,
      };
    }
    return {
      name: t('Unknown bonus'),
      imgUrl: '/img/bonus-box.svg',
    };
  }
  function bonusOnClick(bonus) {
    if (!bonus) return;
    onSetBonus(bonus);
    if (
      bonus.typeId === '2ndWelcomeBonusFreeBoxes' ||
      bonus.typeId === 'WelcomeBonusFreeBoxes'
    ) {
      navigate('./welcome', {
        replace: true,
        state: location.pathname,
      });
    }

    if (bonus.typeId === 'PushNotificationsBonus') {
      navigate('./push', {
        replace: true,
        state: location.pathname,
      });
    }

    if (bonus.typeId === 'ChallengeBonus') {
      navigate('./challenge', {
        replace: true,
        state: location.pathname,
      });
    }
    if (bonus.typeId === 'RecoveryBonus') {
      navigate('./recovery', {
        replace: true,
        state: location.pathname,
      });
    }
    if (bonus.typeId === 'AdBonus') {
      navigate('./health', {
        replace: true,
        state: location.pathname,
      });
    }
  }

  return (
    <>
      {!bonuses.length && <NoBonusDialog onClose={onClose} />}

      {bonuses.length > 0 && (
        <ModalLayout onClose={onClose}>
          <div className="modal-header">
            <h1 className="text-center text-default">{t('Get bonus')}</h1>
          </div>
          <div className="modal-body">
            {bonuses && (
              <SelectionList
                items={bonuses.map((bonus, idx) => {
                  const { name, descr, imgUrl, disabled } = getBonusAttr(bonus);
                  return {
                    name,
                    descr,
                    imgUrl,
                    disabled,
                    //tag: bonus?.last_seen_at === null ? t('New') : null,
                    type: 'menu',
                  };
                })}
                onClick={(idx) => bonusOnClick(bonuses[idx])}
              />
            )}
          </div>
        </ModalLayout>
      )}
    </>
  );
}

function WelcomeBonusDialog({
  logger,
  onClaimBonus,
  hidePushNotificationsTask = true,
  onLogin,
}) {
  const {
    isLoading,
    setBonusSeen,
    getActualWelcomeBonus,
    getActualPushNotificationsBonus,
  } = useBonusesContext();
  const { user } = useUserContext();
  const email = user?.email;
  const [bonus, setBonus] = useState(null);
  const [expired, setExpired] = useLocalStorage('WelcomeBonusExpiredAt', null);

  useEffect(() => {
    if (isLoading) return;
    if (!email) {
      const amount = 500;
      const newExpired =
        !expired || expired <= Date.now()
          ? Date.now() + 60 * 60 * 24 * 1000
          : expired;
      const newBonus = {
        params: { amount },
        expired: newExpired,
      };
      setExpired(newExpired);
      setBonus(newBonus);
    } else {
      const newBonus = hidePushNotificationsTask
        ? getActualWelcomeBonus()
        : getActualPushNotificationsBonus();
      setBonus(newBonus);
      setBonusSeen(newBonus);
    }
  }, [email, isLoading]);

  const navigate = useNavigate();
  const [isInstallDialogShowing, setIsInstallDialogShowing] = useState(false);
  const [enablePushBonus] = useLocalStorage('enable_push_bonus', null);
  const location = useLocation();
  const params = useParams();
  const { token } = params;
  const { initUser } = useUserContext();

  useEffect(() => {
    if (!token) return;
    if (email && isIos() && !isPwa()) {
      //Waiting for install in ios Safari
      //URL is already updated as a start_url for ios PWA
      //Ask user to Add to home screen
      setIsInstallDialogShowing(true);
    }
    const isIosPwaInstalled = loadPersistentState('isIosPwaInstalled');
    if (isIosPwaInstalled) {
      //alert('Normal ios start');
      //It's a normal start of PWA app on iOS
      //PWA's start_url is app/bonus/welcome/token/...
      //Going home
      navigate('/', { replace: true });
    } else {
      //First time PWA start on ios:
      if (
        (!email && isIos() && isPwa()) ||
        (!email && process.env.NODE_ENV !== 'production')
      ) {
        //alert('First ios pwa start');
        //Auth token is received!
        //This is one time code on PWA
        const newToken = tryToParseJSON(atob(token));
        const { email: newEmail, session } = newToken;
        persistState('session', session);
        persistState('email', newEmail);
        persistState('isIosPwaInstalled', true);
        initUser(newEmail).then(() => {
          onLogin(newEmail, '/app/bonus/welcome');
        });
      }
    }
  }, [token]);

  const onInstall = () => {
    logger?.event('click', { target: 'InstallForBonus' });
    if (!token && isIos()) {
      //Generate token for URL
      const session = loadPersistentState('session');
      const newToken = btoa(JSON.stringify({ email, session }));
      const startUrl = `/app/bonus/welcome/token/${newToken}`;
      window.location.replace(startUrl);
    } else if (isAndroid() || isIos()) setIsInstallDialogShowing(true);
    else alert('Please open this web site in mobile browser');
  };

  const onClose = () => {
    navigate('/app', { replace: true });
  };

  const onSignUpNavigate = () => {
    navigate('/app/auth/sign-up', { replace: true });
  };

  const onNotificationsNavigate = () => {
    navigate('/app/settings/notifications', {
      replace: true,
      state: location.pathname,
    });
  };

  return (
    <>
      {!bonus && <NoBonusDialog onClose={onClose} />}

      {bonus && (
        <ModalLayout
          onClose={email && token && isIos() && !isPwa() ? null : onClose}
        >
          <div className="modal-header">
            <h1 className="text-center text-default">{t('Get bonus')}</h1>

            {bonus.expired - Date.now() > 0 && (
              <div className="text-center">
                <img
                  src="/img/time.svg"
                  alt=""
                  style={{
                    width: '11px',
                    marginTop: '0px',
                    marginLeft: '0px',
                    marginRight: '6px',
                  }}
                />
                <span>
                  <small>
                    {millisecondsLeftToStr(
                      Math.max(0, bonus.expired - Date.now())
                    )}
                  </small>
                </span>
              </div>
            )}
          </div>
          <div className="modal-body">
            {hidePushNotificationsTask && (
              <BonusCard
                name={t('Welcome bonus')}
                img="/img/black-box-flat.svg"
                color="var(--theme-gold)"
                amount={bonus?.params?.amount}
                todoList={[
                  {
                    name: t('Create account'),
                    isDone: !!email,
                  },
                  {
                    name: isIos() ? t('Add to Home Screen') : t('Install app'),
                    isDone: isPwa(),
                  },
                ]}
              />
            )}
            {!hidePushNotificationsTask && (
              <BonusCard
                name={t('Notifications bonus')}
                img="/img/black-plane-flat.svg"
                color="var(--theme-crystal)"
                amount={bonus?.params?.amount}
                todoList={[
                  {
                    name: t('Install app'),
                    isDone: isPwa(),
                  },
                  {
                    name: `${t('Enable notifications')}: "${t('Bonuses')}"`,
                    isDone: enablePushBonus === 'true',
                  },
                ]}
              />
            )}
          </div>

          <div className="modal-footer no-mobile-keyboard justify-content-center">
            {!email && (
              <div
                className="btn btn-primary"
                role="button"
                onClick={onSignUpNavigate}
              >
                {t(`Register`)}
              </div>
            )}
            {!!email && !isPwa() && (
              <div
                className="btn btn-primary"
                role="button"
                onClick={onInstall}
              >
                {isIos() ? t('Add to Home Screen') : t(`Install`)}
              </div>
            )}
            {!hidePushNotificationsTask &&
              !!email &&
              isPwa() &&
              enablePushBonus !== 'true' && (
                <div
                  className="btn btn-primary"
                  role="button"
                  onClick={() => onNotificationsNavigate()}
                >
                  {t(`Enable notifications`)}
                </div>
              )}
            {!!email &&
              isPwa() &&
              (enablePushBonus === 'true' || hidePushNotificationsTask) && (
                <div
                  className="btn btn-primary"
                  role="button"
                  onClick={() =>
                    onClaimBonus({
                      ...bonus,
                      bonusColor: hidePushNotificationsTask ? 'gold' : null,
                    })
                  }
                >
                  {t(`Claim`)}
                </div>
              )}
          </div>
        </ModalLayout>
      )}

      {isIos() && (
        <SystemDialog
          title={t('Add to Home Screen')}
          show={isInstallDialogShowing}
          actions={[`OK`]}
          message={
            <>
              <div className="text-nowrap">
                <br />
                1. {t('Press the Share button')}
                <img
                  src="/img/ios/share.svg"
                  alt=""
                  style={{
                    width: '17px',
                    marginTop: '-9px',
                    marginLeft: '8px',
                    marginRight: '8px',
                  }}
                />
              </div>
              <div className="text-nowrap">
                2. {t(`Press 'Add to Home Screen'`)}
                <img
                  src="/img/ios/add.svg"
                  alt=""
                  style={{
                    width: '18px',
                    marginTop: '4px',
                    marginLeft: '8px',
                    marginRight: '8px',
                  }}
                />
              </div>
            </>
          }
          onAction={() => setIsInstallDialogShowing(false)}
        />
      )}
      {isAndroid() && (
        <InstallDialog
          logger={logger}
          show={isInstallDialogShowing}
          onClose={() => setIsInstallDialogShowing(false)}
        />
      )}
    </>
  );
}

function SuccessBonusDialog({ bonus, onClaimedBonus }) {
  return (
    <>
      {bonus && (
        <ModalLayout>
          <div className="modal-header">
            <h1 className="text-center text-default">{t('Congrats!')}</h1>
            <div className="text-center text-muted">
              <small>{t('Bonus is credited')}</small>
            </div>
          </div>
          <div className="modal-body">
            <BonusBox bonusColor={bonus.bonusColor} lidOpen={true} />

            <div className="mt-4">
              <TodoList
                header={t('Bonus details')}
                items={[
                  {
                    name: t('You earned {{amount}} BOXO', {
                      amount: bonus.claimedAmount,
                    }),
                    isDone: true,
                  },
                ]}
              />
            </div>
          </div>

          <div className="modal-footer no-mobile-keyboard justify-content-center">
            {true && (
              <div
                className="btn btn-primary"
                role="button"
                onClick={() => onClaimedBonus(bonus)}
              >
                {t(`Continue`)}
              </div>
            )}
          </div>
        </ModalLayout>
      )}
    </>
  );
}

function ChallengeBonusDialog({ onClaimBonus, onPlay }) {
  const { setBonusSeen, fetchData, isLoading, getActualChallengeBonus } =
    useBonuses();
  const [bonus, setBonus] = useState(null);

  useEffect(() => {
    if (isLoading) return;
    const newBonus = getActualChallengeBonus();
    setBonus(newBonus);
    setBonusSeen(newBonus);
  }, [isLoading]);

  useInterval(() => {
    fetchData();
  }, 2500);

  const left =
    !bonus || !bonus.params
      ? 100
      : Math.max(0, bonus.params.limit - bonus.progress);

  const location = useLocation();
  const navigate = useNavigate();
  const onBack = location.state
    ? () => navigate(location.state, { replace: true })
    : () => navigate('/app', { replace: true });

  const [adsWatched, setAdsWatched] = useState(false);
  const [showAds, setShowAds] = useSessionStorage('show-ads', false);

  const unlocked = left <= 0;
  useEffect(() => {
    if (unlocked && adsWatched) onClaimBonus(bonus);
  }, [unlocked, adsWatched]);

  return (
    <>
      {!bonus && <NoBonusDialog onClose={onBack} />}
      {bonus && (
        <ModalLayout
          hideBackground={showAds}
          hideNavigation={showAds}
          onBack={adsWatched ? null : 'default'}
        >
          {showAds && (
            <AdsContainer
              onDone={() => {
                setAdsWatched(true);
                setShowAds(false);
              }}
              onFail={() => {
                setAdsWatched(false);
                setShowAds(false);
              }}
            />
          )}

          {!showAds && (
            <>
              <div className="modal-header">
                <h1 className="text-center text-default">{t('Get bonus')}</h1>

                {bonus.expired - Date.now() > 0 && (
                  <div className="text-center">
                    <img
                      src="/img/time.svg"
                      alt=""
                      style={{
                        width: '11px',
                        marginTop: '0px',
                        marginLeft: '0px',
                        marginRight: '6px',
                      }}
                    />
                    <span>
                      <small>
                        {millisecondsLeftToStr(
                          Math.max(0, bonus.expired - Date.now())
                        )}
                      </small>
                    </span>
                  </div>
                )}
              </div>
              <div className="modal-body">
                <BonusCard
                  name={t('Achievement bonus')}
                  img="/img/black-progress-flat.svg"
                  amount={bonus?.params?.amount}
                  color="var(--theme-emerald)"
                  todoList={[
                    {
                      name: t('Play to win {{limit}} boxes', {
                        limit: bonus?.params?.limit,
                      }),
                      isDone: bonus?.progress > 0,
                    },
                    {
                      name: t('Wins: {{progress}}', {
                        progress: bonus?.progress,
                      }),
                      isDone: bonus?.progress > 0,
                    },
                    {
                      name: t('Left: {{left}}', { left }),
                      isDone: unlocked,
                    },
                  ]}
                />
              </div>
              <div className="modal-footer no-mobile-keyboard justify-content-center">
                {!unlocked && (
                  <div
                    className="btn btn-primary"
                    role="button"
                    onClick={() => onPlay()}
                  >
                    {t(`Continue`)}
                  </div>
                )}
                {unlocked && (
                  <div
                    className="btn btn-primary"
                    role="button"
                    onClick={() => setShowAds(true)}
                  >
                    ▶ {t(`Claim`)}
                  </div>
                )}
              </div>
            </>
          )}
        </ModalLayout>
      )}
    </>
  );
}

function BonusBox({ bonusColor = '#3FEE76', lidOpen = false }) {
  const { bonus: bonusSound } = useSoundContext();
  const [lidTop, setLidTop] = useState(0);
  const lidOpenPos = 50;
  const lidClosePos = 0;

  useEffect(() => {
    if (lidOpen && bonusSound) {
      setLidTop(lidOpenPos);
      setTimeout(() => {
        bonusSound();
      }, 200);
    } else setLidTop(lidClosePos);
  }, [lidOpen, bonusSound]);

  useInterval(() => {
    if (!lidOpen) return;
    setLidTop(lidTop === lidOpenPos ? lidOpenPos - 8 : lidOpenPos);
  }, 1000);

  const imgStyle = {
    position: 'absolute',
    transform: 'translateX(-50%)',
    left: '50%',
    transition: 'all 1s ease-in-out',
    '--svgcolor': bonusColor, //TODO -- use this color!
  };
  return (
    <>
      <div
        style={{
          position: 'relative',
          height: '30dvh',
          minHeight: '30dvh',
          width: '30dvh',
          left: '50%',
          transform: 'translateX(-50%)',
          //background:
          //  'radial-gradient(circle at 50% 55%, rgb(248 248 248 / 40%), transparent 55%)',
        }}
      >
        <img
          width="55%"
          src="/img/black-box.svg"
          style={{
            ...imgStyle,
            top: `${85}px`,
          }}
        />
        <img
          width="55%"
          src="/img/black-box-bonus.svg"
          style={{
            ...imgStyle,
            top: `${85 - lidTop + Math.ceil((2 * lidTop) / 3)}px`,
          }}
        />
        <img
          width="55%"
          src="/img/black-box-bonus.svg"
          style={{
            ...imgStyle,
            top: `${85 - lidTop + Math.ceil(lidTop / 3)}px`,
          }}
        />

        <img
          width="55%"
          src="/img/black-lid.svg"
          style={{
            ...imgStyle,
            top: `${65 - lidTop}px`,
          }}
        />
      </div>
    </>
  );
}

function RecoveryBonusDialog({ onPlay, onClaimBonus }) {
  const { balance } = useUserContext();
  const { setBonusSeen, getActualRecoveryBonus } = useBonusesContext();
  const [bonus, setBonus] = useState(null);
  useEffect(() => {
    const newBonus = getActualRecoveryBonus();
    setBonus(newBonus);
    setBonusSeen(newBonus);
  }, []);

  const [adsWatched, setAdsWatched] = useState(false);
  const [showAds, setShowAds] = useSessionStorage('show-ads', false);

  const lowBalance = balance < Number(bonus?.params?.minBalance);
  const unlocked =
    bonus?.availableAt === null || bonus?.availableAt - Date.now() < 0;

  const location = useLocation();
  const navigate = useNavigate();
  const onBack = location.state
    ? () => navigate(location.state, { replace: true })
    : () => navigate('/app', { replace: true });

  useEffect(() => {
    if (lowBalance && unlocked && adsWatched) onClaimBonus(bonus);
  }, [lowBalance, unlocked, adsWatched]);

  return (
    <>
      {!bonus && <NoBonusDialog onClose={onBack} />}
      {bonus && (
        <ModalLayout
          hideBackground={showAds}
          hideNavigation={showAds}
          onBack={adsWatched ? null : 'default'}
        >
          <div className="modal-header">
            {!showAds && (
              <h1 className="text-center text-default">{t('Get bonus')}</h1>
            )}
          </div>
          <div className="modal-body">
            {showAds && (
              <AdsContainer
                onDone={() => {
                  setAdsWatched(true);
                  setShowAds(false);
                }}
                onFail={() => {
                  setAdsWatched(false);
                  setShowAds(false);
                }}
              />
            )}

            {!showAds && adsWatched && lowBalance && unlocked && (
              <Loader overlay />
            )}

            {!showAds && !adsWatched && (
              <BonusCard
                name={t('Recovery bonus')}
                img="/img/black-heart-flat.svg"
                amount={bonus?.params?.amount ? bonus?.params?.amount : 10}
                color="var(--theme-ruby)"
                todoList={[
                  {
                    name: t(`Recover your balance smaller than {{amount}}`, {
                      amount: bonus?.params?.minBalance
                        ? bonus?.params?.minBalance
                        : 10,
                    }),
                    isDone: bonus?.params?.minBalance
                      ? balance < Number(bonus?.params?.minBalance)
                      : false,
                  },
                  {
                    name: `${t('Claim the bonus once per hour')}${
                      bonus?.availableAt - Date.now() < 0
                        ? ''
                        : `. ${millisecondsLeftToStr(
                            Math.max(0, bonus?.availableAt - Date.now())
                          )}`
                    }`,
                    isDone:
                      bonus?.availableAt === null ||
                      bonus?.availableAt - Date.now() < 0,
                  },
                ]}
              />
            )}
          </div>

          {!showAds && (
            <div className="modal-footer no-mobile-keyboard justify-content-center">
              {!unlocked && (
                <div
                  className="btn btn-primary"
                  role="button"
                  onClick={() => onBack()}
                >
                  {t(`Back`)}
                </div>
              )}

              {!lowBalance && unlocked && (
                <div
                  className="btn btn-primary"
                  role="button"
                  onClick={() => onPlay()}
                >
                  {t(`Continue`)}
                </div>
              )}

              {lowBalance && unlocked && !adsWatched && (
                <div
                  className="btn btn-primary"
                  role="button"
                  onClick={() => setShowAds(true)}
                >
                  ▶ {t(`Claim`)}
                </div>
              )}
            </div>
          )}
        </ModalLayout>
      )}
    </>
  );
}

function BonusCard({
  color = '#72B37B',
  img = '/img/black-box-flat.svg',
  name = t('Welcome bonus'),
  amount = 10,
  todoList = [
    { name: 'Task 1', isDone: true },
    { name: 'Task 2', isDone: false },
  ],
}) {
  return (
    <>
      <div
        style={{
          height: '30dvh',
          minHeight: '30dvh',
          background: `linear-gradient(80.96deg, ${color} -1.34%, rgba(33, 43, 52, 0.61) 210%)`,
          boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.7)',
          borderRadius: '24px',
          position: 'relative',
        }}
      >
        <div className="row g-0 h-100 flex-nowrap">
          <div className="col-4">
            <img
              src={img}
              alt=""
              style={{
                transform: 'translate(-15%, -50%)',
                top: '50%',
                left: '50%',
                height: '40%',
                position: 'relative',
              }}
            />
          </div>
          <div className="col">
            <div
              style={{
                transform: 'translateY(-50%)',
                top: '47%',
                position: 'relative',
              }}
            >
              <div
                className="text-center fw-bolder fs-5"
                style={{ opacity: 0.7, padding: '10px 15%' }}
              >
                {name}
              </div>
              <div
                className="text-center"
                style={{
                  lineHeight: '3rem',
                  fontSize: '3.4rem',
                  letterSpacing: '-0.01em',
                  color: '#000000',
                  textShadow: '0px 0.5px 0.5px #FFFFFF',
                }}
              >
                {amount}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <TodoList header={t('How to get bonus')} items={todoList} />
      </div>
    </>
  );
}

function NoBonusDialog({}) {
  const location = useLocation();
  const navigate = useNavigate();
  const onBack = location.state
    ? () => navigate(location.state, { replace: true })
    : () => navigate('/app', { replace: true });

  const [show, setShow] = useState(false);
  const clearInterval = useInterval(() => {
    setShow(true);
    clearInterval();
  }, 700);

  return (
    <ModalLayout onBack={null} onClose={null}>
      {show && (
        <>
          <div className="modal-header">
            <h1 className="text-center"></h1>
            <div
              className="text-center text-muted"
              style={{ marginTop: '30dvh' }}
            >
              <img
                src="/img/time.svg"
                alt=""
                style={{
                  width: '11px',
                  marginTop: '-2px',
                  marginLeft: '0px',
                  marginRight: '6px',
                  opacity: 0.4,
                }}
              />
              {t('You have no bonus yet')}
            </div>
          </div>
          <div className="modal-footer no-mobile-keyboard justify-content-center">
            <div className="btn btn-primary" role="button" onClick={onBack}>
              {t(`Back`)}
            </div>
          </div>
        </>
      )}
    </ModalLayout>
  );
}

export function AdsContainer({ playTime, onDone, onFail, onShowing }) {
  const settings = loadPersistentState('settings');
  const logger = useLoggerContext();

  const doFail = (status) => {
    logger.event('error', {
      msg: `ads failed: ${status}`,
      ads: adsList[adsIdx].name,
    });
    if (settings?.canSkipAds) onDone && onDone();
    else onFail && onFail();
  };
  const doDone = () => {
    logger.event('ads', { status: `done`, ads: adsList[adsIdx].name });
    onDone && onDone();
  };

  const [supresOnShowing, setSupresOnShowing] = useState(false);
  const doShowing = () => {
    if (supresOnShowing) return;
    setSupresOnShowing(true);
    logger.event('ads', { status: 'showing', ads: adsList[adsIdx].name });
    onShowing && onShowing();
  };

  const adsIdx = 0; //Rotate ads
  const adsList = [
    {
      name: 'Monetag',
      container: (
        <MonetagContainer
          onDone={doDone}
          onFail={doFail}
          onShowing={doShowing}
        />
      ),
    },
    {
      name: 'Adsense',
      container: (
        <AdsenseContainer
          playTime={playTime}
          onDone={doDone}
          onFail={doFail}
          onShowing={doShowing}
        />
      ),
    },
  ];
  return <>{adsList[adsIdx].container}</>;
}

function HealthBonusDialog({ onClaimBonus }) {
  const { setBonusSeen, getActualAdsBonus } = useBonusesContext();
  const [bonus, setBonus] = useState(null);

  useEffect(() => {
    const newBonus = getActualAdsBonus();
    setBonus(newBonus);
    setBonusSeen(newBonus);
  }, []);

  const [adsWatched, setAdsWatched] = useState(false);
  const [showAds, setShowAds] = useSessionStorage('show-ads', false);

  const unlocked =
    bonus?.availableAt === null || bonus?.availableAt - Date.now() < 0;

  const location = useLocation();
  const navigate = useNavigate();
  const onBack = location.state
    ? () => navigate(location.state, { replace: true })
    : () => navigate('/app', { replace: true });

  useEffect(() => {
    if (unlocked && adsWatched) onClaimBonus(bonus);
  }, [unlocked, adsWatched]);

  return (
    <>
      {!bonus && <NoBonusDialog onClose={onBack} />}

      {bonus && (
        <ModalLayout
          hideBackground={showAds}
          hideNavigation={showAds}
          onBack={adsWatched ? null : 'default'}
        >
          <div className="modal-header">
            {!showAds && (
              <h1 className="text-center text-default">{t('Get bonus')}</h1>
            )}
          </div>
          <div className="modal-body">
            {showAds && (
              <AdsContainer
                onDone={() => {
                  setAdsWatched(true);
                  setShowAds(false);
                }}
                onFail={() => {
                  setAdsWatched(false);
                  setShowAds(false);
                }}
              />
            )}

            {!showAds && adsWatched && <Loader overlay />}

            {!showAds && !adsWatched && (
              <BonusCard
                name={t('Health bonus')}
                img="/img/black-health.svg"
                amount={bonus?.params?.amount ? bonus?.params?.amount : 10}
                color="var(--theme-ruby)"
                todoList={[
                  {
                    name: `${t('Claim the bonus once per hour')}${
                      bonus?.availableAt - Date.now() < 0
                        ? ''
                        : `. ${millisecondsLeftToStr(
                            Math.max(0, bonus?.availableAt - Date.now())
                          )}`
                    }`,
                    isDone:
                      bonus?.availableAt === null ||
                      bonus?.availableAt - Date.now() < 0,
                  },
                ]}
              />
            )}
          </div>

          {!showAds && (
            <div className="modal-footer no-mobile-keyboard justify-content-center">
              {!unlocked && (
                <div
                  className="btn btn-primary"
                  role="button"
                  onClick={() => onBack()}
                >
                  {t(`Back`)}
                </div>
              )}

              {unlocked && !adsWatched && (
                <div
                  className="btn btn-primary"
                  role="button"
                  onClick={() => setShowAds(true)}
                >
                  ▶ {t(`Claim`)}
                </div>
              )}
            </div>
          )}
        </ModalLayout>
      )}
    </>
  );
}

export { BonusListDialog };
