import axios from 'axios';
import { stringifyCycledJSON, urlBase64ToUint8Array } from '../utils';

// Hook for subscribing to push notifications
export const useSubscribe = (logger) => {
  const getSubscription = async () => {
    // Check for ServiceWorker and PushManager support
    if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
      logger.log(`serviceWorker is not supported`, {
        navigatorStr: stringifyCycledJSON(navigator).substring(0, 2000),
        windowStr: stringifyCycledJSON(window).substring(0, 2000),
      });
      throw { errorCode: 'ServiceWorkerAndPushManagerNotSupported' };
    }

    logger.log('serviceWorker is supported');

    // Wait for Service Worker to be ready
    const registration = await navigator.serviceWorker.ready;

    logger.log('serviceWorker is ready');

    // Check for pushManager in registration
    if (!registration.pushManager) {
      logger.log('pushManager is not ready');
      throw { errorCode: 'PushManagerUnavailable' };
    }

    logger.log('pushManager is ready');

    // Check for existing subscription
    var existingSubscription = null;

    try {
      existingSubscription = await registration.pushManager.getSubscription();
    } catch (e) {
      logger.error('getSubscription() failed', { error: e });
      existingSubscription = null;
    }

    // Convert VAPID key for use in subscription
    const publicKey =
      'BMSQnQQG1rCIsoKtbAzLbGBcwBNUGu1sduKPJ3NXiul9yFi6jVptwIeNqZukt2h0wLJbHkz5gdRdP2Am1u6gaX4';
    const convertedVapidKey = urlBase64ToUint8Array(publicKey);

    if (existingSubscription) {
      logger.log('existing subscription found');
      try {
        const permissionState = await registration.pushManager.permissionState({
          applicationServerKey: convertedVapidKey,
          userVisibleOnly: true,
        });
        logger.log('existing subscription state is ' + permissionState);
        if (permissionState !== 'granted') {
          logger.log('existing subscription is denied');
          return null;
        }
      } catch (e) {
        logger.error('permissionState() failed err=' + JSON.stringify(e), {
          error: e,
        });
      }
      return existingSubscription;
    } else {
      logger.log('subscribing...');
      const result = await registration.pushManager.subscribe({
        applicationServerKey: convertedVapidKey,
        userVisibleOnly: true,
      });
      return result;
    }
  };

  /*
    const send = async (email, message, title) => {
    try {
      await axios.post('/api/push/send', {
        email,
        message,
        title,
      });
    } catch (e) {
      console.warn(e);
    }
  };
 */

  const subscribe = async (email) => {
    if (!email) return null;
    try {
      const newSubscription = await getSubscription();
      if (newSubscription) {
        await axios.post('/api/push/subscribe', {
          subscription: newSubscription,
          email,
          baseUrl: window.location.origin,
        });
        logger.log('subscribtion is persisted');
      }
      return newSubscription;
    } catch (e) {
      if (`${e}`.includes('NotAllowedError'))
        logger && logger.action('click-disallow-push-prompt');
      logger && logger.error('Error getSubscription() err=' + e, { error: e });
      return null;
    }
  };

  return { subscribe };
};
