import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { QRCodeSVG } from 'qrcode.react';
import { useCopyToClipboard } from '../shared/hooks/use-hooks';
import { BonusItem, SelectionList } from '../components/terminal';
import { useBonuses } from '../shared/hooks/use-bonus';
import { useLocation, useNavigate } from 'react-router-dom';

const DepositDialog = ({ assets, logger }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [step, setStep] = useState(1);

  const { getActualBonuses } = useBonuses();
  const bonuses = getActualBonuses();

  const [selectedAsset, setSelectedAsset] = useState({
    name: '',
    descr: '',
    networks: [],
  });

  const [selectedNetwork, setSelectedNetwork] = useState({
    name: '',
    descr: '',
    depositAddress: '',
  });

  function Close() {
    navigate('/app', { replace: true });
  }

  function Done() {
    if (location.state) navigate(location.state, { replace: true });
    else navigate('/app', { replace: true });
  }

  const [_, copyToClipboard] = useCopyToClipboard();
  const copyLabelText = t('Copy address');
  const [copyLabel, setCopyLabel] = useState(copyLabelText);

  useEffect(() => {
    if (copyLabel !== copyLabelText) {
      setTimeout(() => {
        setCopyLabel(copyLabelText);
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyLabel]);

  useEffect(() => {
    step && logger?.event('deposit', { step });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <div className="modal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-mobile-navigation row">
            <div className="col-6">
              <div
                className={`text-start text-primary${
                  step >= 1 && step <= 3 ? '' : ' invisible'
                }`}
                role="button"
                onClick={() => {
                  if (step > 1) setStep(step - 1);
                  else Done();
                }}
              >
                <span className="back-symbol" /> {t('Back')}
              </div>
            </div>

            <div className="col-6">
              <div
                className={`${step <= 3 ? '' : ' invisible'}`}
                role="button"
                onClick={() => {
                  Close();
                }}
              >
                <div className="text-end text-primary">{t('Close')}</div>
              </div>
            </div>
          </div>
        </div>

        <div className={`modal-content${step === 1 ? '' : ' visually-hidden'}`}>
          <div className="modal-header">
            <h1 className="text-default text-center">{t('Deposit crypto')}</h1>
          </div>
          <div className="modal-body">
            {bonuses
              .filter((b) => b.name === 'Deposit bonus')
              .map((b, idx) => (
                <BonusItem
                  key={idx}
                  amount={b.amount}
                  name={b.name}
                  descr={b.descr}
                  expiration={b.expiration}
                />
              ))}

            <br />
            <SelectionList
              header={t('Select asset')}
              items={assets.map((asset) => ({ ...asset, type: 'menu' }))}
              onClick={(idx) => {
                setSelectedAsset(assets[idx]);
                setStep(step + 1);
              }}
            />
            <br />
          </div>

          <div className="modal-footer no-mobile-keyboard justify-content-center">
            <div
              className="btn btn-secondary desktop-only"
              role="button"
              onClick={() => {
                setStep(0);
              }}
            >
              {t('Back')}
            </div>
          </div>
        </div>

        <div className={`modal-content${step === 2 ? '' : ' visually-hidden'}`}>
          <div className="modal-header">
            <h1 className="text-default text-center">
              {t('Select a network')}
            </h1>
          </div>
          <div className="modal-body">
            <SelectionList
              items={selectedAsset.networks.map((network) => ({
                ...network,
                type: 'menu',
              }))}
              onClick={(idx) => {
                setSelectedNetwork(selectedAsset.networks[idx]);
                setStep(step + 1);
              }}
            />
          </div>
          <div className="modal-footer no-mobile-keyboard justify-content-center">
            <div
              className="btn btn-secondary desktop-only"
              role="button"
              onClick={() => {
                setStep(step - 1);
              }}
            >
              {t(`Back`)}
            </div>
          </div>
        </div>

        <div className={`modal-content${step === 3 ? '' : ' visually-hidden'}`}>
          <div className="modal-header">
            <h1 className="text-default text-center">
              {t('Deposit')} {selectedAsset.name}
            </h1>
            <div className="text-muted text-center">{selectedAsset.descr}</div>
          </div>
          <div className="modal-body">
            <div className="qr-code">
              <div>
                <QRCodeSVG size="100%" value={selectedNetwork.depositAddress} />
              </div>
              <div
                className="text-center"
                style={{
                  overflowWrap: 'anywhere',
                  width: '100%',
                  fontSize: '12px',
                  fontWeight: '600',
                  paddingTop: '10px',
                  color: 'black',
                  lineHight: '12px',
                }}
              >
                {selectedNetwork.depositAddress}
              </div>
            </div>
            <br />

            <div className="text-default text-center">
              {selectedNetwork.tokenStandard} {t('address for')}{' '}
              {selectedNetwork.descr} ({selectedNetwork.name})
            </div>
          </div>
          <div className="text-center text-muted">
            {t(`Minimal deposit is`)} {selectedNetwork.minDeposit}{' '}
            {selectedAsset.name}
          </div>

          <div className="modal-footer no-mobile-keyboard justify-content-center">
            <div
              className="btn btn-primary"
              role="button"
              onClick={() => {
                if (selectedNetwork.depositAddress) {
                  copyToClipboard(selectedNetwork.depositAddress);
                  setCopyLabel(t('Copied!'));

                  logger &&
                    logger.action('deposit', {
                      step: 100,
                      asset: selectedAsset.name,
                      network: selectedNetwork.name,
                    });
                }
              }}
            >
              {copyLabel}
            </div>

            <div
              className="btn btn-secondary desktop-only"
              role="button"
              onClick={() => {
                setStep(step - 1);
              }}
            >
              {t(`Back`)}
            </div>

            <div
              className="btn btn-secondary desktop-only"
              role="button"
              onClick={() => {
                Done();
              }}
            >
              {t(`Close`)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { DepositDialog };
