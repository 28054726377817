import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import App from './App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Subscription from './pages/subscription';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { register, update } from './serviceWorkerRegistration';
import { UserContext } from './shared/hooks/use-user';
import { LoggerContext } from './shared/hooks/use-logger';
import { UpdaterContext } from './shared/hooks/use-updater';
import { BonusesContext } from './shared/hooks/use-bonus';
import { AdsenseContext } from './shared/hooks/use-adsense';
import { MonetagContext } from './shared/hooks/use-monetag';

i18next.use(LanguageDetector).init({
  resources: {
    en: {
      translation: require('./locales/en.json'),
    },
    es: {
      translation: require('./locales/es.json'),
    },
    de: {
      translation: require('./locales/de.json'),
    },
    tr: {
      translation: require('./locales/tr.json'),
    },
    ru: {
      translation: require('./locales/ru.json'),
    },
  },
});

document.documentElement.lang = i18next.language;

const router = createBrowserRouter([
  {
    path: 'subscription',
    element: <Subscription />,
  },
  {
    path: 'subscription/:email',
    element: <Subscription />,
  },
  {
    path: '*',
    element: (
      <LoggerContext>
        <UpdaterContext>
          <AdsenseContext>
            <MonetagContext>
              <UserContext>
                <BonusesContext>
                  <App />
                </BonusesContext>
              </UserContext>
            </MonetagContext>
          </AdsenseContext>
        </UpdaterContext>
      </LoggerContext>
    ),
  },
]);

if (process.env.NODE_ENV === 'production') console.log = function () {};

const root = ReactDOM.createRoot(document.getElementById('root'));

register();

update();

root.render(<RouterProvider router={router} />);
