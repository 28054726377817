import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Layout, ModalLayout } from '../../shared/layout';
import { useLocalStorage } from '../../shared/hooks/use-hooks';
import { SelectionList } from '../../components/terminal';
import { persistUserProfile } from '../../shared/api/profile';

const switchers = [
  { code: 'en', name: 'English', type: 'button' },
  { code: 'es', name: 'Español', type: 'button' },
  { code: 'de', name: 'Deutsch', type: 'button' },
  { code: 'ru', name: 'Русский', type: 'button' },
  { code: 'tr', name: 'Türkçe', type: 'button' },
];

export function getCurrentLangName() {
  try {
    const lang = JSON.parse(window.localStorage.getItem('lng'));
    return switchers.find((s) => lang && lang.substring(0, 2) === s.code)?.name;
  } catch (e) {
    return '';
  }
}

export default function Lang({ logger, onBack, onClose }) {
  const [lang, setLang] = useLocalStorage('lng', null);

  useEffect(() => {
    persistUserProfile({ lang });
  }, [lang]);

  return (
    <ModalLayout onClose={onClose}>
      <div className="modal-header">
        <h1 className="text-center text-default">{t('Language')}</h1>
      </div>
      <div className="modal-body">
        <SelectionList
          header={t('Select language')}
          items={switchers.map((s, idx) => ({
            ...s,
            tag: lang && lang.substring(0, 2) === s.code ? t('Current') : null,
          }))}
          onClick={(idx) => {
            setLang(switchers[idx].code);
          }}
        />
      </div>
      {/*       <div className="modal-footer no-mobile-keyboard">
        <div
          className={`btn btn-primary`}
          role="button"
          onClick={async () => {
            setLang(null);
          }}
        >
          {t(`Reset`)}
        </div>
      </div>
 */}{' '}
    </ModalLayout>
  );
}
