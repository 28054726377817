import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Loader({ onClick, overlay, redirect = '' }) {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    redirect &&
      setTimeout(
        () =>
          location.pathname === window.location.pathname &&
          navigate(redirect, { replace: true }),
        1000
      );
  }, []);

  return (
    <div className={`loader ${overlay ? 'overlay' : ''}`} onClick={onClick} />
  );
}
