import { useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';

export function useFbPixel() {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const advancedMatching = {};
    const options = {
      autoConfig: true,
      debug: false,
    };

    if (window.location.hostname === 'boxo-fun.online') {
      ReactPixel.init('1356254651724329', advancedMatching, options);
      setIsActive(true);
    }
  });

  const pageView = () => {
    console.log('FB PIXEL - Page view');
    if (isActive) ReactPixel.pageView();
  };

  const track = (event, data) => {
    console.log('FB PIXEL - track', event);
    if (isActive) ReactPixel.track(event, data);
  };

  return { pageView, track };
}
