import { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { SelectionList } from '../components/terminal';
import axios from 'axios';
import { template as confirmaionTemplate } from '../templates/withdraw-confirmaion';
import { template as confirmedTemplate } from '../templates/withdraw-confirmed';
import { useLocation, useNavigate } from 'react-router-dom';
import { priceFmt } from '../shared/utils';

const WithdrawDialog = ({ email, winBalance, onReset, onWithdraw, logger }) => {
  const [step, setStep] = useState(0);
  const [amountValidation, setAmountValidation] = useState('');
  const [addressValidation, setAddressValidation] = useState('');
  const amountInputRef = useRef();
  const addressInputRef = useRef();
  const codeInputRef = useRef();
  const [amount, setAmount] = useState(0);
  const minAmount = 25; //USDT
  const [address, setAddress] = useState('');
  const [sendTime, setSendTime] = useState(0);
  const fee = 1;
  const [code, setCode] = useState(0);
  const [isValidCode, setIsValidCode] = useState(true);
  const winBalanceUsd = Math.floor(winBalance) / 1000;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    logger &&
      logger.landing('step', {
        step,
        address,
        amount,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const modalResults = {
    isClosing: 0,
    isReseting: 1,
    isWithdrawal: 2,
    isDepositing: 3,
  };

  function Done() {
    navigate('/app', { replace: true });
  }

  function validateAddress(a) {
    if (process.env.NODE_ENV !== 'production' && a === 'Trx') return '';
    if (a === '') return t('Enter address');
    if (a.substr(0, 1) !== 'T' || a.length !== 34)
      if (!/T[A-Za-z1-9]{33}/.test(a)) return t('Invalid TRC-20 address');
    return '';
  }

  function validateAmount(a) {
    if (process.env.NODE_ENV !== 'production' && a === '999') return '';
    if (a === '') return t('Enter amount');
    if (isNaN(a * 1)) return t('Enter valid amount');
    if (Math.floor(a * 1000) / 10 > Math.floor(winBalance * 10) / 10)
      return t('Insufficient balance');
    if (a * 1 < minAmount) return t('Minimal amount is') + ' $20';
    return '';
  }

  async function sendConfirmationEmail(email, code, amount, address) {
    setSendTime(Date.now());
    axios
      .post('/api/email/send', {
        email,
        subject: 'Withdrawal confirmation',
        text: confirmaionTemplate.txt
          .replace('9999', code)
          .replace('12.34', amount)
          .replace('Txxx', address),
        html: confirmaionTemplate.html
          .replace('9999', code)
          .replace('12.34', amount)
          .replace('Txxx', address),
      })
      .then(() => {
        setSendTime(Date.now());
      })
      .catch((e) => {
        setSendTime(Date.now() - 60000);
      });
  }

  async function sendConfirmedEmail(email, amount, address) {
    setSendTime(Date.now());
    axios
      .post('/api/email/send', {
        email,
        subject: 'Withdrawal is confirmed',
        text: confirmedTemplate.txt
          .replace('12.34', amount)
          .replace('Txxx', address),
        html: confirmedTemplate.html
          .replace('12.34', amount)
          .replace('Txxx', address),
      })
      .then(() => {
        //
      })
      .catch((e) => {
        //
      });
  }

  return (
    <div className="modal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-mobile-navigation row">
            <div className="col-6">
              <div
                className={`text-start text-primary${
                  step > 0 && step <= 3 ? '' : ' invisible'
                }`}
                role="button"
                onClick={() => {
                  if (step > 0) setStep(step - 1);
                }}
              >
                <span className="back-symbol" /> {t('Back')}
              </div>
            </div>

            <div className="col-6">
              <div
                className={`${step <= 4 ? '' : ' invisible'}`}
                role="button"
                onClick={() => {
                  Done();
                }}
              >
                <div className="text-end text-primary">{t('Close')}</div>
              </div>
            </div>
          </div>
        </div>

        <div className={`modal-content${step === 0 ? '' : ' visually-hidden'}`}>
          <div className="modal-header">
            <h1 className="text-center text-default">{t('Withdraw')}</h1>

            <h1
              className="text-center text-primary"
              style={{
                fontSize: '11vh',
                fontWeight: 100,
                lineHeight: '11vh',
                marginTop: '2vh',
              }}
            >
              {priceFmt(winBalance, 1)}
            </h1>

            <div className="subtitle text-center text-muted">
              ≈${priceFmt((winBalance + 0) / 1000, 2)}
            </div>
          </div>
          <br />
          <div className="modal-body">
            <SelectionList
              items={[
                {
                  name: (
                    <span>
                      {t('Cash out USDT')}
                      <img
                        style={{ width: '20px', margin: '-5px 3px 0px' }}
                        src="/img/usdt.svg"
                      />
                    </span>
                  ),
                  descr: t('Min') + ' $' + minAmount,
                  imgUrl: '/img/withdraw.svg',
                  type: 'menu',
                },
                /*                 {
                  name: t('Deposit'),
                  descr: t('Win more'),
                  imgUrl: '/img/deposit.svg',
                  type: 'menu',
                },
                {
                  name: t('Reset and Play'),
                  descr: t('Send win to balance'),
                  imgUrl: '/img/reset.svg',
                  type: 'button',
                },
 */
              ]}
              onClick={(idx) => {
                if (idx === 0) setStep(step + 1);
                if (idx === 1) {
                  navigate('/app/deposit', {
                    replace: true,
                    state: location.pathname,
                  });
                }
                if (idx === 2) {
                  onReset();
                  Done();
                }
              }}
            />
          </div>
          <br />
          <br />

          <div className="modal-footer no-mobile-keyboard justify-content-center">
            <div
              className="btn btn-secondary desktop-only"
              role="button"
              onClick={() => {
                Done();
              }}
            >
              {t('Close')}
            </div>
          </div>
        </div>

        <div className={`modal-content${step === 1 ? '' : ' visually-hidden'}`}>
          <div className="modal-header">
            <h1 className="text-default text-center">{t('Withdraw USDT')}</h1>
            <div className="subtitle text-muted text-center">
              {t('Available')} ${winBalanceUsd}
            </div>
          </div>

          <div className="modal-body">
            <div className="text-default">{t('Enter wallet address')}</div>
            <input
              ref={addressInputRef}
              type="text"
              id="typeAddress"
              placeholder="TRC-20 for Tron network (TRX)"
            />
            <div
              className={`invalid-feedback${
                addressValidation === '' ? ' invisible' : ''
              }`}
            >
              {addressValidation}
            </div>
          </div>

          <div className="modal-footer justify-content-center">
            <div
              className="btn btn-secondary desktop-only"
              role="button"
              onClick={() => {
                setStep(0);
              }}
            >
              {t('Back')}
            </div>

            <div
              className="btn btn-primary"
              role="button"
              onClick={() => {
                const newAddress = addressInputRef.current.value;
                const newAddressValidation = validateAddress(newAddress);
                setAddressValidation(newAddressValidation);
                if (newAddressValidation !== '') {
                  addressInputRef.current.focus();
                } else {
                  setAddress(newAddress);
                  setStep(step + 1);
                }
              }}
            >
              {t('Next')}
            </div>
          </div>
        </div>

        <div className={`modal-content${step === 2 ? '' : ' visually-hidden'}`}>
          <div className="modal-header">
            <h1 className="text-default text-center">{t('Withdraw USDT')}</h1>
            <div className="subtitle text-muted text-center">
              {t('Available')} ${winBalanceUsd}
            </div>
          </div>

          <div className="modal-body">
            <div className="row">
              <div className="col-6 text-default">{t('Amount')}</div>
              <div
                className="col-6 text-end text-primary"
                role="button"
                onClick={() => {
                  setAmount(winBalanceUsd);
                  amountInputRef.current.value = winBalanceUsd;
                }}
              >
                {t('Max')}
              </div>
            </div>

            <input
              ref={amountInputRef}
              type="number"
              id="typeAmount"
              placeholder={t('USDT amount')}
            />
            <div
              className={`invalid-feedback${
                amountValidation === '' ? ' invisible' : ''
              }`}
            >
              {amountValidation}
            </div>
          </div>

          <div className="modal-footer justify-content-center">
            <div
              className="btn btn-secondary desktop-only"
              role="button"
              onClick={() => {
                setStep(0);
              }}
            >
              {t('Back')}
            </div>

            <div
              className="btn btn-primary"
              role="button"
              onClick={() => {
                const newAmount = amountInputRef.current.value;
                const newAmountValidation = validateAmount(newAmount);
                setAmountValidation(newAmountValidation);
                if (newAmountValidation !== '') {
                  amountInputRef.current.focus();
                } else {
                  setAmount(newAmount * 1);

                  const newCode = (
                    process.env.NODE_ENV !== 'production'
                      ? 1234
                      : Math.random() * 10000
                  )
                    .toFixed(0)
                    .padStart(4, '0');
                  setCode(newCode);
                  sendConfirmationEmail(email, newCode, newAmount, address);
                  setStep(step + 1);
                }
              }}
            >
              {t('Next')}
            </div>
          </div>
        </div>

        {/* Confirm */}

        <div className={`modal-content${step === 3 ? '' : ' visually-hidden'}`}>
          <div className="modal-header">
            <h1 className="text-center">{t('Confirm withdrawal')}</h1>
          </div>

          <div className="modal-body">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <div className="row">
                  <div className="col-6 text-muted">{t('Crypto asset')}</div>
                  <div className="col-6 text-end">USDT</div>
                </div>
              </li>
              <li className="list-group-item">
                <div className="row">
                  <div className="col-6 text-muted">{t('Address')}</div>
                  <div
                    className="col-6 text-end"
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {address}
                  </div>
                </div>
              </li>
              <li className="list-group-item">
                <div className="row">
                  <div className="col-6 text-muted">{t('Withdrawal fee')}</div>
                  <div className="col-6 text-end">${fee.toFixed(2)} USDT</div>
                </div>
              </li>

              <li className="list-group-item">
                <div className="row">
                  <div className="col-6 text-muted">{t('Amount')}</div>
                  <div className="col-6 text-end">
                    ${(amount - fee).toFixed(2)} USDT
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className="modal-footer no-mobile-keyboard">
            <div
              className="btn btn-secondary desktop-only"
              role="button"
              onClick={() => {
                Done();
              }}
            >
              {t('Cancel')}
            </div>
            <div
              className="btn btn-primary"
              role="button"
              onClick={async () => {
                setStep(step + 1);
              }}
            >
              {t('Confirm')}
            </div>
          </div>
        </div>

        <div className={`modal-content${step === 4 ? '' : ' visually-hidden'}`}>
          <div className="modal-header">
            <h1>{t('Confirm withdrawal')}</h1>
            <div
              className="text-default"
              style={{
                whiteSpace: 'nowrap',
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {t('We have sent a code to')} <b>{email}</b>
            </div>
          </div>

          <div className="modal-body">
            <input
              ref={codeInputRef}
              type="text"
              placeholder={t('Enter the code')}
              onChange={() => {
                setIsValidCode(true);
              }}
            />
            <div
              className={`invalid-feedback${isValidCode ? ' invisible' : ''}`}
            >
              {t('Invalid code')}
            </div>

            <div className="text-center">
              <small className="text-muted">
                {t('Did not receive email?')}
                {Date.now() - sendTime < 60000
                  ? ` Resend in ${Math.max(
                      0,
                      60 - (Date.now() - sendTime) / 1000
                    ).toFixed(0)} sec`
                  : ''}
              </small>
              <br />

              <div
                role="button"
                className={`btn btn-link text-muted text-default${
                  Date.now() - sendTime < 60000 ? ' invisible' : ''
                }`}
                onClick={() => {
                  sendConfirmationEmail(email, code, amount, address);
                }}
              >
                <small>{t('Send again')}</small>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div
              className="btn btn-secondary desktop-only"
              role="button"
              onClick={() => {
                Done();
              }}
            >
              {t('Cancel')}
            </div>
            <div
              className="btn btn-primary"
              role="button"
              onClick={async () => {
                if (code === codeInputRef.current.value) {
                  setIsValidCode(true);
                  setStep(step + 1);
                  onWithdraw(amount, address);
                } else {
                  setIsValidCode(false);
                  codeInputRef.current.focus();
                }
              }}
            >
              {t('Confirm')}
            </div>
          </div>
        </div>

        <div className={`modal-content${step === 5 ? '' : ' visually-hidden'}`}>
          <div className="modal-header">
            <h1 className="text-primary-accent text-center">{t('Success!')}</h1>
            <div className="text-muted text-center">
              {t('Withdrawal is successfully requested')}
            </div>

            <img
              src="/img/success.svg"
              alt=""
              style={{
                width: '30%',
                display: 'block',
                margin: '3svh auto',
              }}
            />

            <div className="text-default text-center">
              {t('Processing may take up to 24 hours')}
            </div>
          </div>
          <div className="modal-body"></div>
          <div className="modal-footer justify-content-center">
            <div
              className="btn btn-primary"
              role="button"
              onClick={() => {
                sendConfirmedEmail(email, amount, address);
                Done(modalResults.isWithdrawal);
              }}
            >
              {t('Continue')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { WithdrawDialog };
